// // 测试
// const VUE_APP_WS_URL = 'ws://121.196.205.21:8080/websocket';
// const VUE_APP_BASEURL = 'http://121.196.205.21:8080';

// 正式
// const VUE_APP_WS_URL = 'wss://www.saxtrader.net/websocket';
// const VUE_APP_BASEURL = 'https://www.saxtrader.net';
// const VUE_APP_WS_URL = 'wss://www.galaxytrader.cloud/websocket';
// const VUE_APP_BASEURL = 'https://www.galaxytrader.cloud';
let VUE_APP_WS_URL;
let VUE_APP_BASEURL;

if (process.env.NODE_ENV === 'development') {
    // 测试
    VUE_APP_WS_URL = 'ws://127.0.0.1:8180/websocket';//测试
    VUE_APP_BASEURL = 'http://127.0.0.1:8180';//测试
} else if (process.env.NODE_ENV === 'production') {
    //生产环境
    VUE_APP_WS_URL = 'wss://finance-api.demo.eb7.icu/websocket';
    VUE_APP_BASEURL = 'https://finance-api.demo.eb7.icu';
}
export { VUE_APP_WS_URL, VUE_APP_BASEURL };
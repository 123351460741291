import Vue from 'vue'
import Vuex from 'vuex'
import { VUE_APP_WS_URL } from '../config.js'
import i18n from '@/common/lang';
import { login,loginByTg} from '@/api/login.js'
import { getUserInfo } from "@/api/user";
import { listContents } from '@/api/market'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    innerWidth: 0,
    wsUrl: VUE_APP_WS_URL+"/message",
    marketWsList: [],
    currentLanguage: localStorage.getItem('language')||'en',
    //不显示底部导航的页面
    footerWhiteList: ["reset", "login", "register"],
    lginWhiteList: ["google"],
    isLogin: localStorage.getItem('isLogin')||false,
    isPc: true,
    userInfo: localStorage.getItem('userInfo')? JSON.parse(localStorage.getItem('userInfo')):{},
    token: localStorage.getItem('token')||'',
    contentsList: [],
    langIndex: 0,
    langList: [
      // 'zh': Object.assign(require('@/common/lang/zh-CN'), zhLocale),
      // 'en': Object.assign(require('@/common/lang/en'), enLocale),
      // 'ja': Object.assign(require('@/common/lang/ja'), enLocale),
      // 'ko': Object.assign(require('@/common/lang/ko'), enLocale),
      // 'de': Object.assign(require('@/common/lang/de'), enLocale),
      // 'fr': Object.assign(require('@/common/lang/fr'), enLocale),
      // 'it': Object.assign(require('@/common/lang/it'), enLocale),
      // 'ar': Object.assign(require('@/common/lang/ar'), enLocale),
      // 'hk': Object.assign(require('@/common/lang/zh-HK'), enLocale),
      // 'es': Object.assign(require('@/common/lang/es'), enLocale),
      {
        name: 'English',
        value: 'en'
      },
      {
        name: '日本語',
        value: 'ja'
      },
      {
        name: '한국어',
        value: 'ko'
      },
      {
        name: 'Deutsch',
        value: 'de'
      },
      {
        name: 'français',
        value: 'fr'
      },
      {
        name: 'Italiano',
        value: 'it'
      },
      {
        name: 'Español',
        value: 'es'
      },
      {
        name: 'العربية',
        value: 'ar'
      },
      {
        name: '繁體中文',
        value: 'hk'
      },
      {
        name: '简体中文',
        value: 'zh'
      },
    ],
    virtualStatus: localStorage.getItem('virtualStatus')||1,
    showBoard: sessionStorage.getItem('showBoard') || 0,
    lastPrice:null,
  },
  getters: {
    marketWsList: state => state.marketWsList,
    getMarkTypeList: () => {
      return [
        {
          label: i18n.t('Market.forex'),
          value: "1",
        },
        {
          label: i18n.t('Market.cypto'),
          value: "2"
        },
        {
          label: i18n.t('Market.stocks'),
          value: "3"
        },
        {
          label: i18n.t('Market.futures'),
          value: "4"
        },
        {
          label: i18n.t('Market.ETF'),
          value: "5"
        }
      ]
    },
    getContentList: state => state.contentsList,
    getUserInfo: state => state.userInfo,
    langList: state => state.langList,
    langIndex: state => state.langIndex,
    virtualStatus: state => state.virtualStatus,
    lastPrice: state => state.lastPrice,
  },
  mutations: {
    set_last_price(state, value) {
      state.lastPrice=value;
    },
    set_isLogin(state, val) {
      state.isLogin=val;
    },
    set_userInfo(state, val) {
      state.userInfo=val;
    },
    SetInnerWidth(state, val) {
      state.innerWidth=val
    },
    CHANGE_WS_MARLET(state, data) {
      state.marketWsList=data;
    },
    changeVirtual(state, val) {
      state.virtualStatus=val;
      localStorage.setItem('virtualStatus', val);
    },
    SET_LANG(state, language) {
      localStorage.setItem('language', language);
      state.currentLanguage=language;
      state.langIndex=state.langList.findIndex((item) => item.value===state.currentLanguage);
      // console.log(state.langIndex);
      // 更新 i18n 的当前语言
      // console.log(i18n);
      i18n.locale=language;



      // state.marketTypeList = state.marketTypeList
    },
    SET_IS_PC(state, val) {
      state.isPc=val;
    },
    SET_CONTENTS(state, val) {
      state.contentsList=val;
    },
    setToken(state, token) {
      state.token=token;
    },
    setShowBoard(state, val) {
      state.showBoard=val;
      sessionStorage.setItem('showBoard', val);
    }
  },
  actions: {
    listContents({ commit, state }, lang) {
      return new Promise((resolve, reject) => {
        listContents(lang).then(res => {
          if (res.code=='200') {
            commit('SET_CONTENTS', res.result);
          }
          resolve(res);
        }).catch(err => {
          reject(err);
        })
      })
    },
    loginOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')
        localStorage.removeItem('isLogin')
        commit('setToken', '')
        commit('set_isLogin', false)
        commit('set_userInfo', {})
        resolve();
      })
    },
    loginByTg({commit,state},params){
      return new Promise((resolve, reject) => {
        loginByTg(params).then(res => {
          if (res.code=='200') {
            localStorage.setItem('token', res.result.token)
            localStorage.setItem('userInfo', JSON.stringify(res.result))
            localStorage.setItem('isLogin', true)
            commit('setToken', res.result.token)
            commit('set_isLogin', true)
            commit('set_userInfo', res.result)
          }
          resolve(res);
        }).catch(err => {
          reject(err);
        })
      })
    },
    login({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        login(params).then(res => {
          if (res.code=='200') {
            localStorage.setItem('token', res.result.token)
            localStorage.setItem('userInfo', JSON.stringify(res.result))
            localStorage.setItem('isLogin', true)
            commit('setToken', res.result.token)
            commit('set_isLogin', true)
            commit('set_userInfo', res.result)
          }
          resolve(res);
        }).catch(err => {
          reject(err);
        })
      })
    },
    GET_USER_INFO({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(res => {
          if (res.code=='200') {
            // localStorage.setItem('token', res.result.token)
            localStorage.setItem('userInfo', JSON.stringify(res.result))
            localStorage.setItem('isLogin', true)
            // commit('setToken', res.result.token)
            commit('set_isLogin', true)
            commit('set_userInfo', res.result)
          }
          resolve(res);
        }).catch(err => {
          reject(err);
        })
      })
    },
    GET_WS_MARKET({ commit, state }) {
      console.log(state.wsUrl);
      try {
        return new Promise((resolve, reject) => {
          const ws=new WebSocket(state.wsUrl);
          ws.onopen=() => {
            console.log("连接成功");
            resolve("连接成功");
          };
          ws.onmessage=e => {
            if(e.data==='连接成功'){
              return;
            }
            const data=JSON.parse(e.data);
            if (e.data.includes("[")) {
              commit('CHANGE_WS_MARLET', data);
            }else{
              commit('set_last_price',data);
            }

          };
          ws.onclose=() => {
            console.log("连接关闭");
          };
          ws.onerror=() => {
            console.log("连接出错");
            reject("连接出错");
          };
        })
      } catch (error) {
        console.error('捕获到异常: '+error.message);
        reject("连接出错");
      }
    }
  },
  modules: {
  }
})
